import { Dispatch } from 'redux'
import { addCache, fetchCache, fetchCacheStatus, removeCache } from 'state/apis/admin/cacheApi'
import { Action, ActionType } from '../../types/admin/cacheType'

export const getCacheStatus = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const cacheItem = await fetchCacheStatus()
      dispatch({
        type: ActionType.FETCH_CACHE_STATUS,
        payload: cacheItem
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const getCache = (pattern: string, type: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const cacheItem = await fetchCache(pattern, type)
      dispatch({
        type: ActionType.FETCH_CACHE,
        payload: cacheItem
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const deleteCache = (pattern: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      await removeCache(pattern)
      dispatch({
        type: ActionType.DELETE_CACHE,
        payload: pattern
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const postCache = (payload: any[]) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      await addCache(payload)
      dispatch({
        type: ActionType.POST_CACHE,
        payload
      })
    } catch (err) {
      console.log(err)
    }
  }
}
