import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { deleteCache, getCache, getCacheStatus, postCache } from 'state/actions/admin/cacheAction'

export default function Cache() {
  const dispatch = useDispatch()
  const { cache } = useSelector((state: RootState) => state.adminCache)
  const [delPattern, setDelPattern] = useState('')
  const [getPattern, setGetPattern] = useState('')
  const [textarea, setTextarea] = useState('')
  const [success, setSuccess] = useState(false)
  const [getType, setGetType] = useState('get')

  const handleRadioChange = event => {
    setGetType(event.target.value)
  }

  useEffect(() => {
    dispatch(getCacheStatus())
  }, [])

  const clearCache = () => {
    if (window.confirm('Are you sure you want to delete the cache?')) {
      console.log({ delPattern })
      dispatch(deleteCache(delPattern))
    }
  }

  const getCacheBtn = () => {
    console.log({ getPattern })
    if (getPattern) dispatch(getCache(getPattern, getType))
  }

  const postToCache = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSuccess(false)
    try {
      const payload = textarea
        .split('\n')
        .map(t => {
          const [key = '', value = '', expiry = ''] = t.split(',')
          return {
            key: key.trim().replace(/\s+/g, ''),
            value: value.trim().replace(/\s+/g, ''),
            expiry: expiry.trim().replace(/\s+/g, '')
          }
        })
        .filter(t => t.key && t.value)
      dispatch(postCache(payload))
    } catch (err) {
      console.error(JSON.stringify(err, null, 2))
    } finally {
      setSuccess(true)
    }
  }

  return (
    <div className="m-5 max-w-sm">
      <div>
        <h3 className="mt-10">Get cache</h3>
        <p>Get cache from pattern.</p>
        <label className="">
          <input type="radio" value="get" checked={getType === 'get'} onChange={handleRadioChange} />
          Get
        </label>
        <br />
        <label className="">
          <input type="radio" value="list" checked={getType === 'list'} onChange={handleRadioChange} />
          List
        </label>
        <input
          type="text"
          value={getPattern}
          placeholder="Cache pattern"
          onChange={e => setGetPattern(e.target.value)}
        />
        <button className="btn-cta mt-5 mb-10" onClick={getCacheBtn}>
          Get cache
        </button>

        <pre className="mb-5">{JSON.stringify(cache, null, 2)}</pre>

        <hr />
        <h3 className="mt-10">Delete cache</h3>
        <p>
          Avoid leaving this empty where possible, if the entire cache is erased then at the very least we need to
          reimport CUSTOMER_POSTCODE_ items.
        </p>
        <input
          type="text"
          value={delPattern}
          placeholder="Cache pattern"
          onChange={e => setDelPattern(e.target.value)}
        />
        <button className="btn-cta mt-5 mb-10" onClick={clearCache}>
          Clear cache
        </button>
      </div>

      <hr />
      <h3 className="mt-10">Add to cache</h3>
      <p>Add to the cache below, avoid if you don't know what this is.</p>
      <p>Format: [key], [value], [expirySeconds] (no spaces)</p>
      <form>
        <textarea
          data-cy="file-input"
          data-testid="textarea"
          style={{ minHeight: '600px', minWidth: '600px', borderRadius: '5px', border: '3px solid #c6dabb' }}
          value={textarea}
          onChange={e => setTextarea(e.target.value)}
        ></textarea>
        <br />

        <button
          style={{
            backgroundColor: '#006685',
            margin: '10px',
            width: '150px',
            padding: '5px',
            borderRadius: '5px',
            color: 'white'
          }}
          onClick={postToCache}
        >
          Add
        </button>
        {success && <div>Added!</div>}
      </form>
    </div>
  )
}
