import Api from '../api'

export function fetchCache(pattern: string, type: string) {
  return new Promise<any>((resolve, reject) => {
    Api.get('wwwREST', `/v1/private/admin/cache?pattern=${pattern}&type=${type}&1=1`, {}) // 1=1 hacky, ask Dean
      .then(res => resolve(res))
      .catch(err => reject(err.message))
  })
}

export function fetchCacheStatus() {
  return new Promise<any>((resolve, reject) => {
    Api.get('wwwREST', '/v1/private/admin/cache/status?1=1', {}) // 1=1 hacky, ask Dean
      .then(res => resolve(res))
      .catch(err => reject(err.message))
  })
}

export function removeCache(pattern: any) {
  return new Promise<any>((resolve, reject) => {
    Api.del('wwwREST', '/v1/private/admin/cache/clear?1=1', { body: { topic: pattern } }) // 1=1 hacky, ask Dean
      .then(res => resolve(res))
      .catch(err => reject(err.message))
  })
}

export function addCache(payload: any[]) {
  return new Promise<any>((resolve, reject) => {
    Api.post('wwwREST', '/v1/private/admin/cache/add?1=1', { body: { add: payload } }) // 1=1 hacky, ask Dean
      .then(res => resolve(res))
      .catch(err => reject(err.message))
  })
}
